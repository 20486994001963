import { useState } from "react";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Container, Grid, Link } from "@mui/material";

// Material Kit 2 PRO React themes
import theme from "assets/theme";

// Material Kit 2 PRO React routes
import FeaturesThree from "./layouts/sections/page-sections/featuers/components/FeaturesThree";
import PricingTwo from "./layouts/sections/page-sections/pricing/components/PricingTwo";
import MKBox from "./components/MKBox";
import MKTypography from "./components/MKTypography";
import DefaultLayout from "./layouts/DefaultLayout";
import FeaturesOne from "./layouts/sections/page-sections/featuers/components/FeaturesOne";
import FaqCollapse from "./pages/Support/HelpCenter/components/FaqCollapse";
import ContactUsTwo from "./layouts/sections/input-areas/contact-sections/components/ContactUsTwo";
import TestimonialsThree from "./layouts/sections/page-sections/testimonials/components/TestimonialsThree";

export default function App() {
  const [collapse, setCollapse] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DefaultLayout>
        <PricingTwo />
        <TestimonialsThree />
        <FeaturesThree />
        <MKBox id="preguntas" component="section" py={10} bgColor="#f0f2f5">
          <Container maxWidth="xxl">
            <Grid container justifyContent="center">
              <Grid item xs={12} md={6} mt={0} mb={6}>
                <MKTypography variant="h3" align="center" fontWeight="bold">
                  Preguntas
                </MKTypography>
              </Grid>
              <Grid item xs={12} md={10}>
                <FaqCollapse
                  title="¿Cuál es el proceso para solicitar el servicio de internet?"
                  open={collapse === 1}
                  onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                >
                  Contáctanos a través de WhatsApp y uno de nuestros representantes estará encantado de
                  ayudarte a seleccionar el plan que mejor se adapte a tus necesidades. Sin papeleos ni contratos
                  de atadura. Simplemente selecciona 'SOLICITAR' en cualquiera de nuestros planes y serás
                  redirigido automáticamente a nuestro WhatsApp.
                </FaqCollapse>
                <FaqCollapse
                  title="¿Cómo puedo realizar el pago del servicio de internet?"
                  open={collapse === 2}
                  onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                >
                  Mediante depósito o transferencia a cuentas bancarias, ya sea a través de internet banking,
                  sucursales bancarias o subagentes bancarios. Nuestros representantes te proporcionarán
                  los detalles necesarios a través de WhatsApp para que puedas completar el proceso
                  sin complicaciones.
                </FaqCollapse>
                <FaqCollapse
                  title="¿Cuál es el tiempo estimado de instalación del servicio de internet?"
                  open={collapse === 3}
                  onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                >
                  Las instalaciones se realizan entre 1 y 3 días laborables luego de haber realizado
                  el depósito o transferencia con el monto correspondiente al modelo de instalación deseado.
                </FaqCollapse>
                <FaqCollapse
                  title="¿Cuáles son los modelos de instalación del servicio de internet?"
                  open={collapse === 4}
                  onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                >
                  <strong>Modelo Uno.</strong> Los equipos son propiedad de la empresa y si en algún momento decide concluir
                  con nuestros servicios debe devolver los mismos en optimas condiciones.
                  <br />
                  <strong>Modelo Dos.</strong> Los equipos pasan a ser propiedad del cliente y si en algún momento decide concluir
                  con nuestros servicios no necesita hacer devoluciones.
                </FaqCollapse>
                <FaqCollapse
                  title="¿Cuál es la fecha de pago del servicio de internet?"
                  open={collapse === 5}
                  onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
                >
                  La fecha de pago corresponde del día 1 al 10 de cada mes. Además, puedes acceder a los detalles
                  de las facturas, estado del servicio y más a través de nuestro portal de clientes
                  pulsando <Link fontWeight='bold' target="_self" href='https://portal.hardnet.com.do'>aquí.</Link>
                </FaqCollapse>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <FeaturesOne />
        <ContactUsTwo />
      </DefaultLayout>
    </ThemeProvider>
  );
}
