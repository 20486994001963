/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAvatar from "components/MKAvatar";
import Icon from "@mui/material/Icon";

function SimpleReviewCard({ icon, name, username, review }) {
  return (
    <Card p={2} sx={{ border: "#117DBF 5px", borderStyle: "solid", height: "100%" }}>
      <MKBox display="grid" justifyContent="center" position="relative" mt={-7}>
        <MKAvatar size="xl" shadow="lg" bgColor="primary" sx={{ position: "relative", zIndex: 2 }}>
          {icon === "SLA" ? "SLA" : <Icon fontSize="large">{icon}</Icon>}
        </MKAvatar>
      </MKBox>
      <MKBox pt={3} px={3} mb={6} textAlign="center">
        <MKTypography variant="h6" fontWeight="bold">
          {name}
        </MKTypography>
        {username && (
          <MKTypography variant="body2" color="text" mb={2}>
            @{username}
          </MKTypography>
        )}
      </MKBox>
    </Card>
  );
}

// Setting default values for the props of SimpleReviewCard
SimpleReviewCard.defaultProps = {
  username: "",
};

// Typechecking props for the SimpleReviewCard
SimpleReviewCard.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  username: PropTypes.string,
  review: PropTypes.string,
};

export default SimpleReviewCard;
