/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import {Container, Grid} from "@mui/material";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import FilledInfoCard from "../../../../../../examples/Cards/InfoCards/FilledInfoCard";

function FeaturesThree() {
  return (
    <MKBox id="servicios" component="section" py={10}>
      <Container maxWidth="xl">
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pb={6}
        >
          <MKTypography variant="h3" mb={1}>
            Servicios
          </MKTypography>
        </Grid>
        <Grid container spacing={3} justifyContent="center" mb={6}>
          <Grid item xs={12} md={6}>
            <FilledInfoCard
              titleColor="primary"
              icon="lan"
              color="white"
              title="Interconexión de Sucursales"
              description="Establecemos enlaces de interconexión entre ubicaciones remotas utilizando nuestra red privada de alta velocidad, enlaces microondas o redes privadas virtuales (VPN) para compartir datos, aplicaciones y recursos de manera ágil y segura."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FilledInfoCard
              titleColor="primary"
              icon="computer"
              color="white"
              title="Redes y Seguridad"
              description="Diseñamos, implementamos y configuramos redes cableadas e inalámbricas, siempre enfocados en aplicar rigurosas medidas de seguridad para proteger la información y los sistemas."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FilledInfoCard
              titleColor="primary"
              icon="dialer_sip"
              color="white"
              title="Centrales VoIP"
              description="Brindamos soluciones de telefonía basadas en VoIP, que permiten a nuestros clientes realizar llamadas de alta calidad a través de la red de datos de manera eficiente y rentable."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FilledInfoCard
              titleColor="primary"
              icon="videocam"
              color="white"
              title="Videovigilancia IP"
              description="Ofrecemos soluciones en seguridad utilizando tecnología avanzada de cámaras IP y sistemas de monitoreo, que permiten la supervisión remota y el acceso a las imágenes en tiempo real."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FeaturesThree;
