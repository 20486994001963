/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import customerService from "assets/images/customer-service.jpg";
import logo from "assets/images/logo_hardnet_WL.png";

function ContactUsTwo() {
  return (
    <MKBox
      id="contactos"
      component="section"
      py={10}
      bgColor="#f0f2f5"
      sx={{ borderBottomRightRadius: "0.75rem", borderBottomLeftRadius: "0.75rem" }}
    >
      <Container maxWidth="xxl">
        <Grid container item px={0} justifyContent={'center'}>
          <MKBox>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={7}>
                <MKBox
                  display="flex"
                  alignItems="start"
                  width="100%"
                  height="100%"
                >
                  <MKBox pl={'0px !important'}>
                    <MKTypography variant="h3" sx={{textAlign: {xs:'center', lg:'inherit'}, mb:6}}>
                      Contacto
                    </MKTypography>
                    <Grid container direction='row' spacing={6} justifyContent={'center'}>
                      <Grid item alignContent='center'>
                        <MKBox display="flex" p={0}>
                          <MKBox component={'img'} src={logo} width={'auto'} height={50}/>
                          <MKTypography
                            component="span"
                            variant="h6"
                            color="text"
                            opacity={1}
                            ml={1}
                            fontWeight="bold"
                          >
                            Hardnet SRL <br/>
                            RNC 132367863
                          </MKTypography>
                        </MKBox>
                      </Grid>
                      <Grid item alignContent='center' >
                        <MKBox display="flex" p={1}>
                          <MKTypography variant="button" color="primary">
                            <i className="fa fa-whatsapp" />
                          </MKTypography>
                          <MKTypography
                            component="span"
                            variant="button"
                            color="text"
                            opacity={0.8}
                            ml={2}
                            fontWeight="regular"
                          >
                            +1 (829) 968 5535
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" color="dark" p={1}>
                          <MKTypography variant="button" color="primary">
                            <i className="fas fa-envelope" />
                          </MKTypography>
                          <MKTypography
                            component="span"
                            variant="button"
                            color="text"
                            opacity={0.8}
                            ml={2}
                            fontWeight="regular"
                          >
                            info@hardnet.com.do
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" color="dark" p={1}>
                          <MKTypography variant="button" color="primary">
                            <i className="fas fa-map-marker-alt" />
                          </MKTypography>
                          <MKTypography
                            component="span"
                            variant="button"
                            color="text"
                            opacity={0.8}
                            ml={2}
                            fontWeight="regular"
                            sx={{maxWidth:{xs:145,sm:'inherit'}}}
                          >
                            Avenida Independencia, Jarabacoa, R.D.
                          </MKTypography>
                        </MKBox>
                      </Grid>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid
                item
                md={0}
                lg={5}
                position="relative"
                sx={{display: {xs:'none', lg:'flex'}}}
              >
                <MKBox component={'img'} width={'100%'} src={customerService} borderRadius={'0.75rem'}/>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ContactUsTwo;
