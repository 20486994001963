/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function FeaturesOne() {
  return (
    <MKBox id="nosotros" component="section" py={10}>
      <Container maxWidth="xl">
        <Grid container alignItems="center">
          <Grid item xs={12} lg={12}>
            <Container maxWidth="xl">
              <MKTypography variant="h3" my={1} pb={5} sx={{ textAlign: "center" }}>
                Nosotros
              </MKTypography>
              <MKTypography variant="body2" color="text" mb={2}>
                Somos una empresa líder en el sector de las telecomunicaciones en Jarabacoa, con una
                amplia trayectoria brindando servicios de internet confiables y de alta velocidad a
                nuestros clientes. Durante años, hemos sido parte integral de la comunidad, conectando
                hogares, empresas y brindando soluciones tecnológicas en el ámbito empresarial.
                <br />
                <br />
                Nos enorgullece ser una empresa autorizada por el organismo regulador de las
                telecomunicaciones en la República Dominicana, INDOTEL. Esto garantiza que cumplimos
                con los estándares y regulaciones más estrictos de la industria, brindando a
                nuestros clientes la confianza de que reciben servicios de calidad.
                <br />
                <br />
                Contamos con un equipo altamente capacitado de ingenieros y técnicos certificados en
                diferentes áreas de la tecnología y nos esforzamos por comprender a fondo las
                necesidades de nuestros clientes para brindar soluciones personalizadas que se ajusten
                a sus requisitos únicos.
              </MKTypography>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FeaturesOne;
