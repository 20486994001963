/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Container, Grid, Stack } from "@mui/material";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";
import prices from "prices";

function pricesSimpleCardHandler(prices) {
  return prices.map((item, index) =>
    <SimplePricingCard key={index + Math.random().toString()} color="primary" description="Oferta por tiempo limitado"
                       price={item.price}
                       action={{ type: "external", route: "https://wa.me/18299685535", label: "Solicitar" }}
                       title={item.title} specifications={item.specifications} />,
  );
}

function PricingTwo() {
  return (
    <MKBox id="planes" component="section" py={10}>
      <Container maxWidth="xl">
        <Grid container item xs={12} justifyContent="center" mx="auto" textAlign="center">
          <Grid item xs={12} sm={12} lg={12} pb={6}>
            <MKTypography variant="h3">Internet</MKTypography>
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <MKTypography variant="body2" color="text">
              Brindamos servicios de internet residenciales, corporativos y dedicados a través
              de fibra óptica y enlaces microondas para garantizar un acceso fluido.
              <br />
              Si deseas una velocidad de conexión más alta, ofrecemos cotizaciones personalizadas
              que se adaptan a tus necesidades específicas.
            </MKTypography>
          </Grid>
        </Grid>
        <Stack direction={{ xs: "column", lg: "row" }} spacing={4} mt={8}>
          {pricesSimpleCardHandler(prices.A)}
        </Stack>
        <Stack direction={{ xs: "column", lg: "row" }} spacing={4} mt={4} mb={6}>
          {pricesSimpleCardHandler(prices.B)}
        </Stack>
      </Container>
    </MKBox>
  );
}

export default PricingTwo;
