const prices = {
  A: [
    {
      title: { up: "5Mbps", down: "3Mbps" },
      price: { value: { normal: "RD$1,195", offert: "RD$895" }, type: "mensual" },
      specifications: ["5Mbps de bajada", "3Mbps de subida", "Transferencia ilimitada"],
    },
    {
      title: { up: "10Mbps", down: "3Mbps" },
      price: { value: { normal: "RD$1,495", offert: "RD$1,195" }, type: "mensual" },
      specifications: ["10Mbps de bajada", "3Mbps de subida", "Transferencia ilimitada"],
    },
    {
      title: { up: "15Mbps", down: "4Mbps" },
      price: { value: { normal: "RD$1,895", offert: "RD$1,595" }, type: "mensual" },
      specifications: ["15Mbps de bajada", "4Mbps de subida", "Transferencia ilimitada"],
    },
    {
      title: { up: "20Mbps", down: "4Mbps" },
      price: { value: { normal: "RD$2,195", offert: "RD$1,895" }, type: "mensual" },
      specifications: ["20Mbps de bajada", "4Mbps de subida", "Transferencia ilimitada"],
    },
  ],
  B: [
    {
      title: { up: "25Mbps", down: "5Mbps" },
      price: { value: { normal: "RD$2,595", offert: "RD$2,295" }, type: "mensual" },
      specifications: ["25Mbps de bajada", "5Mbps de subida", "Transferencia ilimitada"],
    },
    {
      title: { up: "30Mbps", down: "5Mbps" },
      price: { value: { normal: "RD$2,895", offert: "RD$2,595" }, type: "mensual" },
      specifications: ["30Mbps de bajada", "5Mbps de subida", "Transferencia ilimitada"],
    },
    {
      title: { up: "35Mbps", down: "6Mbps" },
      price: { value: { normal: "RD$3,295", offert: "RD$2,995" }, type: "mensual" },
      specifications: ["35Mbps de bajada", "6Mbps de subida", "Transferencia ilimitada"],
    },
    {
      title: { up: "40Mbps", down: "6Mbps" },
      price: { value: { normal: "RD$3,595", offert: "RD$3,295" }, type: "mensual" },
      specifications: ["40Mbps de bajada", "6Mbps de subida", "Transferencia ilimitada"],
    },
  ],
};

export default prices;
