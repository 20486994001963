/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Card from "@mui/material/Card";

function FilledInfoCard({ variant, color, titleColor, icon, title, description, action }) {
  const buttonStyles = {
    width: "max-content",
    display: "flex",
    alignItems: "center",

    "& .material-icons-round": {
      fontSize: "1.125rem",
      transform: `translateX(3px)`,
      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
    },

    "&:hover .material-icons-round, &:focus .material-icons-round": {
      transform: `translateX(6px)`,
    },
  };

  let iconColor = color;

  if (variant === "gradient" && color !== "light") {
    iconColor = "white";
  } else if (variant === "gradient" && color === "light") {
    iconColor = "dark";
  }

  return (
    <Card
      sx={{
        width: "100%",
        minHeight: "227px",
        height: "100%",
        overflow: "hidden",
        boxShadow: ({ boxShadows: { xxxl } }) => xxxl,
      }}
    >
      <MKBox variant={variant} bgColor={color} pt={3.5} pb={3} px={3}>
        <MKBox pt={{ xs: 3, md: 0 }} pl={0} lineHeight={1}>
          <MKTypography
            // display="flex"
            variant="h4"
            color={titleColor ? titleColor : "dark"}
            fontWeight="bold"
            mb={1}
            align="center"
          >
            <MKBox
              mr={2}
              width="2rem"
              height="2rem"
              variant="gradient"
              bgColor="info"
              color="white"
              coloredShadow="info"
              display="inline-block"
              alignItems="center"
              justifyContent="center"
              borderRadius="xl"
              // sx={{ flex: "none" }}
            >
              <Icon fontSize="small">{icon}</Icon>
            </MKBox>
            {title}
          </MKTypography>
          <MKTypography
            display="block"
            variant="body2"
            color={variant === "contained" || color === "light" ? "text" : "white"}
            mt={3}
            mb={2}
            sx={{ textAlign: "center" }}
          >
            {description}
          </MKTypography>
          {action && action.type === "external" ? (
            <MKTypography
              component={MuiLink}
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="body2"
              fontWeight="regular"
              color={variant === "contained" ? color : "white"}
              sx={buttonStyles}
            >
              {action.label} <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          ) : null}
          {action && action.type === "internal" ? (
            <MKTypography
              component={Link}
              to={action.route}
              variant="body2"
              fontWeight="regular"
              color={variant === "contained" ? color : "white"}
              sx={buttonStyles}
            >
              {action.label} <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          ) : null}
        </MKBox>
      </MKBox>
    </Card>
  );
}

// Setting default props for the FilledInfoCard
FilledInfoCard.defaultProps = {
  variant: "contained",
  color: "info",
  action: false,
};

// Typechecking props for the FilledInfoCard
FilledInfoCard.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "white",
  ]),
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default FilledInfoCard;
