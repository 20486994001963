// @mui material components
import Icon from "@mui/material/Icon";

const routes = [
  {
    name: "Internet",
    route: "/planes",
    icon: <Icon>router</Icon>,
  },
  {
    name: "Servicios",
    route: "/servicios",
    icon: <Icon>add_home_work</Icon>,

  },
  {
    name: "Preguntas",
    route: "/preguntas",
    icon: <Icon>quiz</Icon>,
  },
  {
    name: "Nosotros",
    route: "/nosotros",
    icon: <Icon>group</Icon>,
  },
  {
    name: "Contacto",
    route: "/contactos",
    icon: <Icon>call</Icon>,
  },
];

export default routes;
