import { useRef } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import tvPeople from "assets/images/tv-people-7.jpg";
import { Link, useLocation } from "react-router-dom";
import DefaultNavbar from "../examples/Navbars/DefaultNavbar";
import routes from "../routes";
import MKBox from "../components/MKBox";
import MKTypography from "../components/MKTypography";
import MKButton from "../components/MKButton";
import CenteredFooter from "../examples/Footers/CenteredFooter";

function DefaultLayout({ children }) {
  const headerRef = useRef(null);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://portal.hardnet.com.do",
          label: "clientes",
          color: "primary",
        }}
        relative
        withIcons={false}
        sticky
      />
      <MKBox
        id='top'
        ref={headerRef}
        minHeight="90vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(rgba("#f0f2f5", 0.6), rgba("#f0f2f5", 0.6))}, url(${tvPeople})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} md={7} lg={6} flexDirection="column" justifyContent="center">
            <MKTypography
              variant="h1"
              color={'white'}
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Conéctate al mundo sin límites ni contratos!
            </MKTypography>
            <Stack direction="row" spacing={1} mt={3}>
              <MKButton
                component={Link}
                to="/planes"
                color="white"
                onClick={() => {
                    document.getElementById("planes").scrollIntoView(true);
                }}
              >
                Conoce mas
              </MKButton>
            </Stack>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 2,
          boxShadow: ({ boxShadows: { xxxl } }) => xxxl,
        }}
      >
        {children}
      </Card>
      <MKBox pt={0} px={0} mt={0}>
        <CenteredFooter />
      </MKBox>
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
