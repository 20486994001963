/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import SimpleReviewCard from "examples/Cards/ReviewCards/SimpleReviewCard";

// Images

function TestimonialsThree() {
  return (
    <MKBox bgColor="#f0f2f5" pb={10}>
      <Container>
        <Grid
          container
          justifyContent="center"
          sx={{ pt: 10, pb: 0, position: "relative", zIndex: 3 }}
        >
          <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
            <MKTypography variant="h3">Beneficios en nuestros servicios de Internet</MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={8} sx={{ mt: 6, mb: 6 }}>
          <Grid item xs={12} md={4}>
            <SimpleReviewCard icon={"SLA"} name="SLA de hasta 99.9%" />
          </Grid>
          <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 0 } }}>
            <SimpleReviewCard icon={"access_time"} name="Monitoreo 24/7 en el Centro de Operaciones de la Red (NOC)"/>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 0 } }}>
            <SimpleReviewCard icon="report" name="Alertas en caso de caídas en el servicio y soporte personalizado" />
          </Grid>
          <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 0 } }}>
            <SimpleReviewCard icon="cell_tower" name="Conectividad dual con IPv4 e IPv6" />
          </Grid>
          <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 0 } }}>
            <SimpleReviewCard icon="network_check" name="Opción de ancho de banda flexible y capacidad de ráfagas" />
          </Grid>
          <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 0 } }}>
            <SimpleReviewCard icon="web" name="Portal de clientes para administración de servicios" />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default TestimonialsThree;
